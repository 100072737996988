<template>
  <div class="app" style="background-color: #ffffff;">
    <loader />
    <b-card no-body class="p-2 no-bg no-border" style="background-color: #ffffff;">
      <b-card-body style="border: 1px solid #f68b2a;">
        <b-form @submit.prevent="handleSubmit()">
            <b-row class="mt-4" v-if="logo">
              <b-col cols="12" sm="6" lg="6" class="avatar-center">
                  <img
                    :src="getLogo()"
                    class="img-responsive img-avatar avatar-center"
                    style="height: 125px;width: 125px;"
                  />
              </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="text-center">
                    <strong>{{clienteInfo ? `Olá ${clienteInfo},` : ''}}</strong>
                </b-col>
            </b-row>
          <b-row>
            <b-col cols="12" class="text-center">
              <strong
                v-if="!isBlock"
              >De 0 a 10, o quanto você indicaria a {{nome_loja}} a um amigo ou familiar?</strong>
            </b-col>
          </b-row>
          <b-row class="animated fadeIn">
            <b-col cols="12" class="text-center">
              <div
                class="btn-group btn-group-toggle"
                data-toggle="buttons"
                v-for="(item, index) in arrValues"
                :key="index"
              >
                <label
                  v-if="!isBlock"
                  class="mr-1 number-opcao btn btn-primary"
                  :class="resposta.nota_indicacao === Number(index) ? 'active': ''"
                  v-bind:style="{ background: item.color}"
                >
                  <input
                    type="radio"
                    name="options"
                    id="item.valor"
                    autocomplete="off"
                    v-model="resposta.nota_indicacao"
                    :value="item.valor"
                  />
                  {{item.valor}}
                </label>
              </div>
            </b-col>
          </b-row>
          <b-row style="margin-top: 3%;">
            <b-col cols="12" class="text-center">
              <strong
                v-if="!isBlock"
              >Descreva o que motivou a sua nota sobre a indicação. (opcional)</strong>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="text-center">
              <b-form-textarea
                id="textarea"
                v-model="resposta.descricao"
                placeholder="Escreva aqui..."
                rows="3"
                max-rows="6"
                v-if="!isBlock"
              ></b-form-textarea>
              <strong v-if="isBlock">{{msgBlock}}</strong>
            </b-col>
          </b-row>
        </b-form>
        <b-row style="margin-top: 2%;">
          <b-col cols="12" class="text-center">
            <b-button
              type="submit"
              variant="primary"
              class="px-4 text-uppercase text-white text-bold"
              @click="saveRetorno()"
              v-if="!isBlock"
            >Enviar Resposta</b-button>
            <b-button
              type="submit"
              variant="primary"
              class="px-4 text-uppercase text-white text-bold"
              @click="saveNotReceive()"
              v-if="isReceive"
            >Sim</b-button>
            <b-button
              type="submit"
              variant="primary"
              class="px-4 text-uppercase text-white text-bold"
              @click="notReceive(false)"
              v-if="isReceive"
              style="margin-left: 8px;"
            >Não</b-button>
          </b-col>
        </b-row>
        <b-row style="margin-top: 2%;">
          <b-col cols="12" class="text-center">
            <strong>Grupo Jetro © 2019 Taylor Minhas Vendas</strong>
          </b-col>
          <b-col cols="12" class="text-center" v-if="!isReceive">
            <b-link
              style="text-decoration: underline;cursor: pointer"
              @click="notReceive(true)"
              v-if="!isBlockReceive"
            >Não quero mais receber pesquisas de avaliação do atendimento</b-link>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import Loader from "@/core/components/Loader";
import swal from "sweetalert";
import API from "@/core/api";

export default {
  name: "PesquisaNps",
  components: {
    Loader
  },
  data() {
    return {
      resposta: {},
      logo: null,
      nome_loja: "",
      clienteInfo: "",
      isBlock: false,
      isReceive: false,
      isBlockReceive: false,
      msgBlock: "Mensagem não informada",
      arrValues: [
        { valor: 0, color: "#d91c1a" },
        { valor: 1, color: "#ed2124" },
        { valor: 2, color: "#ff3333" },
        { valor: 3, color: "#ff8a15" },
        { valor: 4, color: "#ff9933" },
        { valor: 5, color: "#ffcc33" },
        { valor: 6, color: "#ffc20d" },
        { valor: 7, color: "#99cc33" },
        { valor: 8, color: "#87b32d" },
        { valor: 9, color: "#43c043" },
        { valor: 10, color: "#339933" }
      ]
    };
  },
  computed: {},
  methods: {
    async search() {
      try {
        const schema = "ge_" + this.resposta.grupoempres;
        const com_nps_envio_id = this.resposta.com_nps_envio_id;

        if (!schema || !com_nps_envio_id) {
            this.isBlock = true;
            this.msgBlock = "URL não encontrada.";
            this.isBlockReceive = true;
            return;
        }

        const npsEnvioAPI = new API("nps_envio");
        const { data } = await npsEnvioAPI.get({ com_nps_envio_id, schema });
 
        if (data[0].nota_indicacao || data[0].nao_quero_receber === 1) {
          this.isBlock = true;
          this.msgBlock = "Essa pesquisa já foi respondida. Obrigado!";
          this.isBlockReceive = data[0].nao_quero_receber === 1;
          return;
        }

        const fd_loja = data[0].adm_estabel_centres_id;
        const lojasAPI = new API("lojas");
        const data2 = await lojasAPI.get({ fd_loja, schema });
        this.nome_loja = data2.data[0].fd_nome;

        const gestao = data2.data[0].fd_gestao;

        if (gestao) {
          const gestoesAPI = new API("gestoes");
          const data4 = await gestoesAPI.get({ gestao, schema });
          this.logo = data4 && data4.data && data4.data.length && (data4.data[0].fd_usa_logo_minhas_vendas == 'S') && data4.data[0].fd_arquivo_logo_minhas_vendas;
        }

        const codigo = data[0].fd_cliente_id;
        const digito = data[0].fd_cliente_dg;
        const infoClienteAPI = new API('info-cliente-nps');
        const data3 = await infoClienteAPI.get({ schema, codigo, digito });
        this.clienteInfo = data3.data[0].fd_nome;
      } catch (e) {
        console.error(e);
      }
    },
    getLogo() {
      const schema = "ge_" + this.resposta.grupoempres;

      return `${process.env.VUE_APP_API_URL}get-photo/${schema}/${this.logo}`
    },
    async saveRetorno() {
      const schema = "ge_" + this.resposta.grupoempres;
      const { com_nps_envio_id, descricao, nota_indicacao } = this.resposta;

      if (typeof nota_indicacao === 'undefined') {
        return swal({
          text:
            "Nota é obrigatória para envio da pesquisa de avaliação de atendimento."
        });
      }

      const retornoAPI = new API("update_nps_retorno");
      await retornoAPI.post({
        com_nps_envio_id,
        descricao,
        nota_indicacao,
        schema
      });
      this.isBlock = true;
      this.msgBlock =
        "Avaliação regristada com sucesso. Obrigado pela sua opinião!";
    },
    notReceive(valor) {
      this.isBlock = valor;
      this.isReceive = valor;
      this.msgBlock = valor
        ? "Sua opinião é muito importante para nós, mas a sua vontade de não mais receber pesquisas é mais ainda. " +
          "Deseja realmente não mais receber pesquisas de avaliação de atendimento?"
        : "";
      if (!valor) {
        this.search();
      }
    },
    async saveNotReceive() {
      const schema = "ge_" + this.resposta.grupoempres;
      const { com_nps_envio_id } = this.resposta;
      const notReceiveAPI = new API("update_nps_nao_receber");
      await notReceiveAPI.post({
        com_nps_envio_id,
        schema
      });
      this.isBlock = true;
      this.isReceive = false;
      this.isBlockReceive = true;
      this.msgBlock =
        "Seu desejo foi regristada com sucesso! Obrigado pela sua atenção!";
    },
    getJsonFromUrl(url) {
      var query = url;
      var result = {};
      query.split("&").forEach(function(part) {
        var item = part.split("=");
        result[item[0]] = decodeURIComponent(item[1]);
      });
      return result;
    }
  },
  created() {
    const params = window.atob(this.$route.params.param);
    this.resposta = {};
    this.resposta = this.getJsonFromUrl(params);
    this.search();
  }
};
</script>

<style scoped lang="css">
.btn {
  margin-bottom: 4px;
}
.number-opcao {
  font-weight: bold !important;
  font-family: "Helvetica", "Arial", sans-serif;
  border-color: #fff;
}
.btn-primary:hover.number-opcao {
  color: #fff !important;
  background-color: #0ae7ef !important;
  border-color: #0ae7ef !important;
}
.btn-primary.active{
  color: #fff !important;
  background-color: #0ae7ef !important;
  border-color: #0ae7ef !important;
}
strong {
  color: #535d67 !important;
}
</style>
