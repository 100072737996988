var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app", staticStyle: { "background-color": "#ffffff" } },
    [
      _c("loader"),
      _c(
        "b-card",
        {
          staticClass: "p-2 no-bg no-border",
          staticStyle: { "background-color": "#ffffff" },
          attrs: { "no-body": "" }
        },
        [
          _c(
            "b-card-body",
            { staticStyle: { border: "1px solid #f68b2a" } },
            [
              _c(
                "b-form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.handleSubmit()
                    }
                  }
                },
                [
                  _vm.logo
                    ? _c(
                        "b-row",
                        { staticClass: "mt-4" },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "avatar-center",
                              attrs: { cols: "12", sm: "6", lg: "6" }
                            },
                            [
                              _c("img", {
                                staticClass:
                                  "img-responsive img-avatar avatar-center",
                                staticStyle: {
                                  height: "125px",
                                  width: "125px"
                                },
                                attrs: { src: _vm.getLogo() }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "text-center", attrs: { cols: "12" } },
                        [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm.clienteInfo
                                  ? "Olá " + _vm.clienteInfo + ","
                                  : ""
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "text-center", attrs: { cols: "12" } },
                        [
                          !_vm.isBlock
                            ? _c("strong", [
                                _vm._v(
                                  "De 0 a 10, o quanto você indicaria a " +
                                    _vm._s(_vm.nome_loja) +
                                    " a um amigo ou familiar?"
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "animated fadeIn" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "text-center", attrs: { cols: "12" } },
                        _vm._l(_vm.arrValues, function(item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "btn-group btn-group-toggle",
                              attrs: { "data-toggle": "buttons" }
                            },
                            [
                              !_vm.isBlock
                                ? _c(
                                    "label",
                                    {
                                      staticClass:
                                        "mr-1 number-opcao btn btn-primary",
                                      class:
                                        _vm.resposta.nota_indicacao ===
                                        Number(index)
                                          ? "active"
                                          : "",
                                      style: { background: item.color }
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.resposta.nota_indicacao,
                                            expression:
                                              "resposta.nota_indicacao"
                                          }
                                        ],
                                        attrs: {
                                          type: "radio",
                                          name: "options",
                                          id: "item.valor",
                                          autocomplete: "off"
                                        },
                                        domProps: {
                                          value: item.valor,
                                          checked: _vm._q(
                                            _vm.resposta.nota_indicacao,
                                            item.valor
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.$set(
                                              _vm.resposta,
                                              "nota_indicacao",
                                              item.valor
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(item.valor) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        }),
                        0
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticStyle: { "margin-top": "3%" } },
                    [
                      _c(
                        "b-col",
                        { staticClass: "text-center", attrs: { cols: "12" } },
                        [
                          !_vm.isBlock
                            ? _c("strong", [
                                _vm._v(
                                  "Descreva o que motivou a sua nota sobre a indicação. (opcional)"
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "text-center", attrs: { cols: "12" } },
                        [
                          !_vm.isBlock
                            ? _c("b-form-textarea", {
                                attrs: {
                                  id: "textarea",
                                  placeholder: "Escreva aqui...",
                                  rows: "3",
                                  "max-rows": "6"
                                },
                                model: {
                                  value: _vm.resposta.descricao,
                                  callback: function($$v) {
                                    _vm.$set(_vm.resposta, "descricao", $$v)
                                  },
                                  expression: "resposta.descricao"
                                }
                              })
                            : _vm._e(),
                          _vm.isBlock
                            ? _c("strong", [_vm._v(_vm._s(_vm.msgBlock))])
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticStyle: { "margin-top": "2%" } },
                [
                  _c(
                    "b-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    [
                      !_vm.isBlock
                        ? _c(
                            "b-button",
                            {
                              staticClass:
                                "px-4 text-uppercase text-white text-bold",
                              attrs: { type: "submit", variant: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.saveRetorno()
                                }
                              }
                            },
                            [_vm._v("Enviar Resposta")]
                          )
                        : _vm._e(),
                      _vm.isReceive
                        ? _c(
                            "b-button",
                            {
                              staticClass:
                                "px-4 text-uppercase text-white text-bold",
                              attrs: { type: "submit", variant: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.saveNotReceive()
                                }
                              }
                            },
                            [_vm._v("Sim")]
                          )
                        : _vm._e(),
                      _vm.isReceive
                        ? _c(
                            "b-button",
                            {
                              staticClass:
                                "px-4 text-uppercase text-white text-bold",
                              staticStyle: { "margin-left": "8px" },
                              attrs: { type: "submit", variant: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.notReceive(false)
                                }
                              }
                            },
                            [_vm._v("Não")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticStyle: { "margin-top": "2%" } },
                [
                  _c(
                    "b-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    [
                      _c("strong", [
                        _vm._v("Grupo Jetro © 2019 Taylor Minhas Vendas")
                      ])
                    ]
                  ),
                  !_vm.isReceive
                    ? _c(
                        "b-col",
                        { staticClass: "text-center", attrs: { cols: "12" } },
                        [
                          !_vm.isBlockReceive
                            ? _c(
                                "b-link",
                                {
                                  staticStyle: {
                                    "text-decoration": "underline",
                                    cursor: "pointer"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.notReceive(true)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "Não quero mais receber pesquisas de avaliação do atendimento"
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }